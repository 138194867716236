import { CodeRisk, Findings, Reports } from "@/api";
import Vue from "vue";
import { ScmIntegrationType } from "$/dynamo";
import { FindingsHistory, HistoryType } from "$/finding-types";
import { DeoptimizedADORiskReport } from "$/report/ado/ado-report-utils";
import { DismissedItemsFilter } from "$/report/dismissed-items-filter";
import { ADORiskReport, ADORiskReportQueryType, RiskReport, RiskReportType } from "$/report/risk-report/risk-report";
import { CodeRiskFinding } from "$/sast/code-risk-finding";
import { BaseRequestParams } from "@/api/api-base";
import { UIRiskReportItem } from "@/models/risks-page-assets";
import { Dismissals } from "@/state/dismissals-state";
import { ProfileNames } from "@/state/profile-names-state";
import { ObservableMap } from "@/utility-types/observable-map";

export const RiskReports = new (class ReportsState {
  public constructor() {
    Vue.observable(this);
  }

  private readonly dismissalItemFilterer = new DismissedItemsFilter();
  public riskReports = new Map<RiskReportType, RiskReport<RiskReportType>>();
  public filter: Partial<UIRiskReportItem> | null = null;

  //Map of integrationType/org/project(if ado)/repo -> SemgrepFinding[]
  public codeRiskReport = new ObservableMap<`${CodeRiskFinding["dataType"]}.${CodeRiskFinding["scanner"]}.${string}`, CodeRiskFinding[]>();

  public async initReport(riskReportType: RiskReportType, params: BaseRequestParams = {}) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [report, _ignored] = await Promise.all([Reports.getRisk(riskReportType, params), ProfileNames.init()]);
    if (!report || "error" in report) {
      console.warn(`Failed to get risk report for ${riskReportType}`, report);
      return;
    }
    this.dismissReportItems([report]);
    this.riskReports.set(riskReportType, report);
  }

  public async init(params: BaseRequestParams = {}): Promise<void> {
    const [riskReportResponses] = await Promise.all([Reports.getAllRisks(params), ProfileNames.init()]);
    this.dismissReportItems(riskReportResponses);
    for (const reportResponse of riskReportResponses) {
      this.riskReports.set(reportResponse.summary.queryType, reportResponse);
    }
  }

  public async getRiskByProjectId(
    reportType: ADORiskReportQueryType,
    integrationOrgIdAndType: string,
    projectId: string,
    params: BaseRequestParams = {},
    optimize = true
  ): Promise<ADORiskReport | DeoptimizedADORiskReport | null> {
    const report = await Reports.getRiskByProjectId(reportType, integrationOrgIdAndType, projectId, params, optimize);
    if (!report) {
      return null;
    }
    //TODO: make dismissReportItems support DeoptimizedADORiskReport (reportItems is an iterable, and we can't convert it to an array because it will crash on large reports)
    //this.dismissReportItems([report]);
    return report;
  }

  private dismissReportItems(reports: RiskReport<RiskReportType>[]) {
    if (!Dismissals.recentlyDismissedItems.size) {
      return;
    }
    for (const report of reports) {
      this.dismissalItemFilterer.filterOutDismissedItems(report, Array.from(Dismissals.recentlyDismissedItems.values()));
    }
  }

  //TODO: move to code-risk-state
  public async initCodeRiskReport(
    integrationType: ScmIntegrationType,
    org: string,
    project: string | undefined,
    repo: string,
    dataType: CodeRiskFinding["dataType"],
    scanner: CodeRiskFinding["scanner"],
    silent = false
  ) {
    const codeRiskReport = await CodeRisk.getCodeRiskReport(silent, integrationType, org, project, repo, dataType, scanner);
    const fullRepoName = this.generateFullRepoName(integrationType, org, project, repo);
    if (codeRiskReport) {
      this.codeRiskReport.set(`${dataType}.${scanner}.${fullRepoName}`, codeRiskReport);
      console.log(`CodeRisk report loaded. Size: ${codeRiskReport?.length ?? -1}`);
    }
  }

  public async initSingleCodeRiskFinding(sortKey: string): Promise<CodeRiskFinding[] | null> {
    const codeRiskReport = await CodeRisk.getCodeRiskSingleFindingReport(sortKey);
    const [finding] = codeRiskReport || [];
    if (!finding) {
      return null;
    }

    const fullRepoName = this.generateFullRepoName(finding.integrationType, finding.integrationOrgId, finding.project, finding.repo);
    if (codeRiskReport) {
      this.codeRiskReport.set(`${finding.dataType}.${finding.scanner}.${fullRepoName}`, codeRiskReport);
    }
    console.log(`CodeRisk report loaded. Size: ${codeRiskReport?.length ?? -1}`);
    return codeRiskReport;
  }

  public async initMultipleCodeRiskFindings(sortKeys: string[]): Promise<CodeRiskFinding[] | null> {
    const codeRiskReport = await CodeRisk.getCodeRiskMultipleFindingReport(sortKeys);

    console.log(`CodeRisk report loaded. Size: ${codeRiskReport?.length ?? -1}`);
    return codeRiskReport;
  }

  public async tryLoadFindingHistory(sortKey: string): Promise<FindingsHistory<HistoryType>[]> {
    try {
      const history = await Findings.getFindingHistory(sortKey);
      return history;
    } catch (e) {
      console.error("Error loading finding history", e);
      return [];
    }
  }

  public generateFullRepoName(integrationType: ScmIntegrationType, org: string, project: string | undefined, repo: string) {
    const fullRepoName = [integrationType, org, project, repo].nonNullable()
      .map((part) => part.encodeURI())
      .join("/");
    return fullRepoName;
  }

  public parseFullRepoName(fullRepoName: string) {
    const [integrationType, org, ... projectRepo] = fullRepoName.split("/")
      .map((part) => part.decodeURI());

    const project = projectRepo.length < 2 ? undefined : projectRepo[0];
    const repo = projectRepo.length < 2 ? projectRepo[0] : projectRepo[1];
    return {
      integrationType: integrationType as ScmIntegrationType,
      org,
      project,
      repo
    };
  }
})();
