export const SEVERITIES = ["critical", "high", "medium", "low", "info", "unknown"] as const;

export type RiskSeverity = (typeof SEVERITIES)[number];

export const RiskSeverity = {
  displayName: {
    critical: "Critical",
    high: "High",
    medium: "Medium",
    low: "Low",
    info: "Info",
    unknown: "Unknown"
  } as const satisfies Record<RiskSeverity, string>,
  map: {
    critical: "Critical 💥",
    high: "High 🚨",
    medium: "Medium ⚠️",
    low: "Low ⬇️",
    info: "Info ℹ️",
    unknown: "Unknown ❓"
  } satisfies Readonly<Record<RiskSeverity, string>>,
  is(severity: string | undefined): severity is RiskSeverity {
    return SEVERITIES?.includes(severity as RiskSeverity);
  }
} as const;
