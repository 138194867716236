
<!-- :key used to force a redraw, see https://stackoverflow.com/questions/63337982/toggle-v-navigation-drawers-expand-on-hover-not-resizing-content -->
<v-navigation-drawer
  class="component layout-component navigation-drawer"
  floating
  :app="$state.Layout.drawer.show"
  permanent
  width="200px"
  v-if="$state.Auth.authenticated"
  v-show="$state.Layout.drawer.show"
  :expand-on-hover="!$state.Layout.drawer.anchored"
  :key="$state.Layout.drawer.anchored"
  :mini-variant.sync="$state.Layout.drawer.mini">
  <div class="d-flex justify-space-around pt-1">
    <arnica-logo :background="$vuetify.theme.dark ? 'dark' : 'light'" :mini="$state.Layout.drawer.mini" class="d-flex" />
  </div>
  <div class="scoped-scroll">
    <v-spacer></v-spacer>

    <v-list dense nav>
      <template v-for="(item, i) in sideBarNavItems">
        <div class="tooltip-wrapper" :key="item.title" :title="item.tooltip">
          <v-list-item  :ripple="false" :to="item.link" link :inactive="true" :disabled="item.disabled">
            <v-list-item-icon v-if="$state.Layout.drawer.mini">
              <v-icon disabled>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-else>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <template v-for="child in item.children">
          <div v-if="showInsideBar(child)" class="tooltip-wrapper" :key="child.title" :title="child.tooltip">
            <v-list-item dense :ripple="false" link class="sub-menu-item" :to="child.link" :disabled="child.disabled">
              <v-list-item-icon>
                <v-icon x-small>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.title }}
                  <v-icon right x-small v-if="child.frontIcon" :title="child.frontIcon.tooltip">{{ child.frontIcon.icon }}</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </template>
        <v-divider :key="i" v-if="showInsideBar(item, true) && i < sideBarNavItems.length - 1"></v-divider>
      </template>
    </v-list>
  </div>
  <v-slide-y-reverse-transition>
    <v-footer fixed color="transparent" v-show="!$state.Layout.drawer.mini">
      <v-btn x-small plain @click.stop="$state.Layout.drawer.togglePinned()">
        <template v-if="$state.Layout.drawer.anchored"> <v-icon small>mdi-pin-off</v-icon>Unpin </template>
        <template v-else> <v-icon small>mdi-pin</v-icon>Pin </template>
      </v-btn>
    </v-footer>
  </v-slide-y-reverse-transition>
</v-navigation-drawer>
