<template>
  <v-card class="component highlight">
    <pre :class="`${showLines ? ' line-numbers' : ''}`" :data-start="startLine"><code ref="code" :class="`language-${language}`" v-html-sanitize="markedCode"></code></pre>
  </v-card>
</template>

<script lang="ts">
import Prism from "prismjs";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-c";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-cpp";
import "prismjs/components/prism-csharp";
import "prismjs/components/prism-css";
import "prismjs/components/prism-docker";
import "prismjs/components/prism-go";
import "prismjs/components/prism-hcl";
import "prismjs/components/prism-java";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/components/prism-kotlin";
import "prismjs/components/prism-python";
import "prismjs/components/prism-ruby";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-yaml";
import "prismjs/plugins/keep-markup/prism-keep-markup";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import { Component, Prop, Vue } from "vue-property-decorator";
import { wrapWithTags } from "$/sast/semgrep/utils";

// leader-line has no types in npm/DefinitelyTyped
//TODO: add type information
declare type LeaderLine = unknown;

@Component
export class Highlight extends Vue {
  @Prop({
    type: String,
    required: false,
    default: ""
  })
  protected readonly language!: string;

  @Prop({
    type: String,
    required: true
  })
  protected readonly code!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  protected readonly showLines!: boolean;

  @Prop({
    type: Number,
    required: false,
    default: 1
  })
  protected readonly startLine!: number;

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  protected readonly highlights!: { startIndex: number; endIndex: number; class?: string }[];

  @Prop({
    type: String,
    required: true
  })
  protected readonly id!: string;

  private lines: unknown[] = [];

  protected get markedCode(): string {
    if (this.highlights.length > 1) {
      return wrapWithTags(this.code, this.highlights);
    }
    return this.code.escapeHtml();
  }

  public async mounted(): Promise<void> {
    Prism.highlightAll(false);
    //TODO: add type information for LeaderLine and contribute to DefinitelyTyped
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.lines.forEach((l: any) => l.remove());
    if (this.highlights.length > 1) {
      const $ref = this.$refs["code"] as HTMLElement | undefined;
      if (!$ref) {
        return;
      }
      const finding = { element: $ref.getElementsByClassName("finding")[0], color: "red" };
      const taintSource = { element: $ref.getElementsByClassName("taint-source")[0], color: "purple" };
      const intermediates = [...$ref.getElementsByClassName("intermediate-var")].map((e) => ({
        element: e,
        color: "orange"
      }));
      if (!finding || !taintSource) {
        return;
      }

      const lines = [taintSource, ...(intermediates ?? []), finding];

      for (let i = 0; i < lines.length - 1; i++) {
        const item = lines[i];
        const nextItem = lines[i + 1];
        if (item?.element && nextItem?.element) {
          //TODO: add type information
          this.lines
            .push
            // eslint-disable-next-line no-undef
            /*
            new LeaderLine(item.element, nextItem.element, {
              startPlugColor: item.color,
              endPlugColor: nextItem.color,
              gradient: true,
              size: 2,
              dash: { animation: true },
              path: "straight"
            })
*/
            ();
        }
      }
    }

    window.addEventListener("scroll", this.onWindowScroll, { capture: true });
  }

  private onWindowScroll(): void {
    //TODO: use https://github.com/anseki/anim-event
    // AnimEvent.add(function() {
    //   line.position();
    // });
    this.lines?.forEach((l: any) => l.position());
  }

  public destroyed(): void {
    //TODO: add type information
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.lines.forEach((l: any) => l.remove());
    this.lines = [];
    window.removeEventListener("scroll", this.onWindowScroll);
  }
}

export default Highlight;
</script>

<style lang="scss">
.component.highlight {
  font-size: 13px !important;
  line-height: 1.5 !important;
  background-color: $dark-background !important;
  .v-application & {
    code {
      background-color: transparent !important;
    }

    pre {
      &[class*="language-"] {
        padding-top: 16px;
        padding-bottom: 16px;
        background-color: inherit !important;

        //.theme--dark > {
        //  background-color: $dark-background !important;
        //}
        //.theme--light > {
        //  background-color: $light-background !important;
        //}
      }
    }
  }

  box-sizing: border-box;
  width: calc(100vw - 250px);

  mark {
    &.finding {
      border: 1px solid red !important;
      background-color: rgba(darkred, 0.3) !important;
    }

    &.taint-source {
      border: 1px solid #bb1fbb !important;
      background-color: rgba(purple, 0.2) !important;
    }

    &.intermediate-var {
      border: 1px solid darkorange !important;
      background-color: rgba(darkorange, 0.3) !important;
    }

    color: inherit !important;
  }

  //.line-numbers .line-numbers-rows {
  //  pointer-events: inherit !important;
  //}
  //.line-numbers-rows > span {
  //
  //}
}
</style>
