import Axios, { AxiosError } from "axios";
import { UIException } from "$/errors/ui-exception";

export class AxiosUserException extends Error implements UIException {
  public readonly error!: string;
  public readonly id!: string;
  public readonly statusCode!: number;
  public readonly ui!: true;

  private constructor(error: AxiosError, uiException: UIException) {
    super(error.message, { cause: error });
    Object.assign(this, uiException);
  }

  public static tryCreate(error: unknown): AxiosUserException | undefined {
    if (!Axios.isAxiosError(error)) {
      return undefined;
    }

    const data = error.response?.data;
    if (UIException.is(data)) {
      return new AxiosUserException(error, data);
    }
    if (error.code === "ECONNABORTED") {
      return new AxiosUserException(error, {
        message: "Request timed out. Please try again later.",
        id: "",
        error: error.message,
        statusCode: 504,
        ui: true
      });
    }
    return undefined;
  }
}
