interface Set<T> {
  /**
   * Appends new elements with a specified value to the end of the Set.
   */
  addRange(values: T[]): Set<T>;

  /**
   * Appends new elements with a specified value to the end of the Set.
   */
  addMany(...values: T[]): Set<T>;

  /**
   * Delete elements with a specified values.
   */
  deleteRange(values: T[]): Set<T>;

  /**
   * Delete elements with a specified values.
   */
  deleteMany(...values: T[]): Set<T>;

  /**
   * Returns an array of values.
   */
  toArray(): T[];

  /**
   * Returns a new set with the elements that are present in both sets (intersect).
   * @param other The other set to compare with.
   */
  and(other: Set<T>): Set<T>;

  /**
   * Returns a new set with the elements that are present in either set (union).
   * @param other The other set to compare with.
   */
  or(other: Set<T>): Set<T>;

  /**
   * Returns a new set with the elements that are present in either set but not in both.
   * @param other The other set to compare with.
   */
  xor(other: Set<T>): Set<T>;

  /**
   * Returns a new set with the elements that are present in this set but not in the other set (difference).
   * @param other The other set to compare with.
   */
  not(other: Set<T>): Set<T>;
}

Set.prototype.addRange ||= function addRange<T>(this: Set<T>, values: T[]): Set<T> {
  for (const value of values) {
    this.add(value);
  }
  return this;
};

Set.prototype.addMany ||= function addMany<T>(this: Set<T>, ...values: T[]): Set<T> {
  return this.addRange(values);
};

Set.prototype.deleteRange ||= function deleteRange<T>(this: Set<T>, values: T[]): Set<T> {
  for (const value of values) {
    this.delete(value);
  }
  return this;
};

Set.prototype.deleteMany ||= function deleteMany<T>(this: Set<T>, ...values: T[]): Set<T> {
  return this.deleteRange(values);
};

Set.prototype.toArray ||= function toArray<T>(this: Set<T>): T[] {
  return Array.from(this);
};

Set.prototype.and ||= function and<T>(this: Set<T>, other: Set<T>): Set<T> {
  const result = new Set<T>();
  for (const value of this) {
    if (other.has(value)) {
      result.add(value);
    }
  }
  return result;
};

Set.prototype.or ||= function or<T>(this: Set<T>, other: Set<T>): Set<T> {
  return new Set<T>(this).addMany(...other);
};

Set.prototype.xor ||= function xor<T>(this: Set<T>, other: Set<T>): Set<T> {
  const result = new Set<T>();
  result.addMany(...this.not(other));
  result.addMany(...other.not(this));
  return result;
};

Set.prototype.not ||= function not<T>(this: Set<T>, other: Set<T>): Set<T> {
  const result = new Set<T>();
  for (const value of this) {
    if (!other.has(value)) {
      result.add(value);
    }
  }
  return result;
};
