import { RouteConfig } from "vue-router";
import { Lazy } from "$/utility-types";
import { Scope } from "@/interfaces/scope";
import { hasRequiredScopes } from "@/plugins/route-guards/scoped-routes";
import { Tier } from "@/state";
import { ObservablePromiseBase } from "@/utility-types/observable-promise";

export class SideBarItem {
  private constructor(private readonly route: RouteConfig, private readonly parent?: SideBarItem) {}

  public static from(route: RouteConfig) {
    return new SideBarItem(route);
  }

  public get title(): string | null {
    return this.meta?.title ?? null;
  }

  public get icon(): string | null {
    return this.meta?.icon ?? null;
  }

  public get frontIcon(): { icon: string; tooltip?: string } | null {
    return this.meta?.frontIcon ?? null;
  }

  public get link(): string {
    const path = this.route.path;
    const parentPath = this.parent?.route.path ?? null;
    const link = parentPath ? `${parentPath}/${path}` : path;
    return link.replace(/:.*/, "");
  }

  public get children(): SideBarItem[] {
    return this.route.children?.map((c) => new SideBarItem(c, this)) ?? [];
  }

  public get inactive(): boolean | null {
    return this.route.name === "";
  }

  public get disabled(): boolean {
    return this.tier.disabled;
  }

  public get tooltip(): string | null {
    return this.tier.tooltip;
  }

  public get sidebar(): boolean {
    if (this.parent && !this.parent.sidebar) {
      return false;
    }
    let sidebar = this.meta?.sidebar ?? false;
    if (sidebar instanceof Lazy) {
      sidebar = sidebar.value;
    }
    if (sidebar instanceof ObservablePromiseBase) {
      sidebar = sidebar.value;
    }
    return !!sidebar;
  }

  public accessibleToUser(scopes: PartialRecord<Scope, true | undefined>): boolean {
    return hasRequiredScopes(scopes, this.meta?.scopes);
  }

  private get meta(): RouteConfig["meta"] | null {
    return this.route.meta ?? null;
  }

  private get tier(): {
    disabled: boolean;
    tooltip: string | null;
  } {
    const tier = this.meta?.tier;
    switch (typeof tier) {
      case "undefined":
        return { disabled: false, tooltip: null };
      case "function":
        return { disabled: !tier(Tier), tooltip: null };
      case "object": {
        const enabled = tier.enabled(Tier);
        return { disabled: !enabled, tooltip: enabled ? null : tier.message };
      }
      default:
        return { disabled: false, tooltip: null };
    }
  }
}
