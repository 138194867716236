import { ApiBase } from "./api-base";
import { Organization, UserManagementConfig } from "$/dynamo";
import { CreateOrganizationResponse } from "$/interfaces/ui-api/response/create-organization-response";
import { Auth } from "@/state/auth-state";

class OrgsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "organization", name: "OrgsApi" });
  }

  public async create(orgName: string): Promise<CreateOrganizationResponse> {
    const res = await this.client.post<CreateOrganizationResponse>("", { orgName });
    return res.data;
  }

  public async leave(): Promise<void> {
    await this.client.delete<void>("");
  }

  public async getAll(): Promise<Organization[]> {
    const res = await this.client.get<Organization[]>("", { silent: true });
    return res.data;
  }

  public async getManagementConfig(): Promise<UserManagementConfig> {
    if (!Auth.scopes.user_ro) {
      return { managedBySso: false };
    }
    const res = await this.client.get<UserManagementConfig>("metadata/config/management");
    return res.data;
  }

  public async updateManagementConfig(managementConfig: UserManagementConfig): Promise<void> {
    await this.client.put<void>("metadata/config/management", managementConfig);
  }

  public async updateName(orgName: string) {
    await this.client.patch<void>("", { orgName });
  }
}

export const Orgs = new OrgsApi();
