import { Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { CodeRiskFindingType, FindingActivity, FindingStatuses, SecretFindingType } from "$/finding-types";
import { codeRiskTitleFilter } from "@/filters/code-risk-title-filter";
import { CodeStatus } from "@/pages/risks/code-status";
import { CODE_RISK_STATUSES } from "@/pages/risks/code/code-risk-constants";
import { CodeRiskFilters } from "@/pages/risks/code/code-risk-filters";
import { sortByRisk } from "@/pages/risks/code/sort-by-risk";
import { UICodeRiskFinding } from "@/pages/risks/code/ui-code-risk-item";
import { getSecretTypeTitleCase, UNIFIED_FINDING_STATUSES } from "@/pages/risks/secrets/secrets-constants";
import { getVerificationResultName } from "@/pages/risks/secrets/secrets-helper";
import { findingTypeText } from "@/pages/risks/filters/finding-type-filters";
import { getResourceSCMUrl } from "@/utils";
import { findingLinkFilter, scmAssetTextFilter } from "@/filters";
import { RiskSeverity } from "$/risk-severity";
import { LanguageToExtensions } from "$/utils/file-types";
import { activityTypeText } from "@/pages/risks/filters/activity-type-filters";

// Convert each array to Record<string, boolean> (hardcoded)
const commonHeaderTypes = {
  "metadata.status": true,
  risk: true,
  asset: true,
  path: true,
  fileExtension: false,
  dataType: true,
  title: true,
  firstDetectedOn: false,
  lastUpdateDate: true,
  detectedOn: false,
  // folder: false,
  // language: false
  issue: false,
  activity: false
} as const;

const codeRiskHeaderTypes = {
  license: false,
  highestEPSSScore: false,
  slaStart: true,
  packageType: false,
  packageName: false,
  isDevDependency: false,
  hasDirectVulnerabilities: false,
  /**
   * means last detected on, //TODO: refactor rename it now that we don't have it in Dynamo
   */
  detectedOn: false,
  hasFix: false,
  vulnerabilityClass: false
} as const;

const secretHeaderTypes = {
  specificType: true,
  verificationResult: true,
  "metadata.additionalInfo": false,
  /**
   * means last detected on, //TODO: refactor rename it now that we don't have it in Dynamo
   */
  detectedOn: true
} as const;

const allHeaderTypes = {
  ...commonHeaderTypes,
  ...codeRiskHeaderTypes,
  ...secretHeaderTypes
} as const;

type SecretHeaderType = keyof typeof secretHeaderTypes;
type CodeRiskHeaderType = keyof typeof codeRiskHeaderTypes;
type CommonHeaderType = keyof typeof commonHeaderTypes;
export type HeaderType = keyof typeof allHeaderTypes;

/**
 * Handles display text for the CSV report
 */
export const headerTypeDisplayTextFactory: PartialRecord<HeaderType, (item: UICodeRiskFinding) => string> = {
  "metadata.status": (item) => (item.metadata?.status ? UNIFIED_FINDING_STATUSES[item.metadata.status]?.text ?? "Unknown" : "N/A"),
  asset: (item) => `${item.integrationType}://${scmAssetTextFilter(item, { showOrg: true, showBranch: true })}`,
  title: (item) => codeRiskTitleFilter(item),
  path: (item) => {
    try {
      return findingLinkFilter(item, "commit") || getResourceSCMUrl(item, "path") || `${item.path}#${item.lineNumber}`;
    } catch (e) {
      console.error(`Error getting link for ${item.path}#${item.lineNumber}`, e);
      return `${item.path}#${item.lineNumber}`;
    }
  },
  dataType: (item) => findingTypeText(item.dataType),
  verificationResult: (item) => (item.verificationResult && getVerificationResultName(item.verificationResult)) ?? "Unknown",
  specificType: (item) => getSecretTypeTitleCase(item.specificType),
  issue: (item) => item.issue?.url || "",
  risk: (item) => RiskSeverity.displayName[item.risk],
  activity: (item) => item.activity?.map((activity) => activityTypeText(activity)).join(", ") || ""
};

type SecretTypedDataTableHeader = DataTableHeader & { value: SecretHeaderType };
type CodeRiskTypedDataTableHeader = DataTableHeader & { value: CodeRiskHeaderType };
type CommonTypedDataTableHeader = DataTableHeader & { value: CommonHeaderType };
export type TypedDataTableHeader = DataTableHeader & { value: HeaderType };

export const commonHeaders: CommonTypedDataTableHeader[] = [
  { text: "Risk", value: "risk", groupable: true, sort: sortByRisk, width: "50px" },
  { text: "Status", value: "metadata.status", groupable: true, width: "90px", sortable: true },
  { text: "Asset", value: "asset", groupable: true, sortable: false, width: "15vw" },
  { text: "File", value: "path", groupable: false, sortable: true },
  {
    text: "Code Language",
    value: "fileExtension",
    groupable: true,
    sortable: true,
    filter: (value, search, item) => {
      if (value !== "fileExtension") {
        return false;
      }
      if (!search) {
        return true;
      }
      const lowerSearch = search.toLowerCase();
      if (item.includes(lowerSearch)) {
        return true;
      }
      return Object.entries(LanguageToExtensions).some(([language, extensions]) => language.includes(lowerSearch) && extensions.some((ext) => ext.includes(item)));
    }
  },
  { text: "Type", value: "dataType", groupable: true, sortable: true, cellClass: "nowrap" },
  { text: "Description", value: "title", groupable: true, width: "17vw" },
  { text: "First Detected", value: "firstDetectedOn", groupable: false, sortable: true },
  { text: "Last Updated", value: "lastUpdateDate", groupable: false, sortable: true },
  { text: "Last Detected", value: "detectedOn", groupable: true, sortable: true },
  { text: "Issue", value: "issue", sortable: true },
  { text: "Activity", value: "activity", groupable: true, sortable: false }
  // { text: "Language", value: "language", groupable: true, sortable: true },
  // { text: "Folder", value: "folder", groupable: true, sortable: true }
];

export const secretHeaders: (CommonTypedDataTableHeader | SecretTypedDataTableHeader)[] = [
  { text: "Secret Type", value: "specificType", groupable: true },
  { text: "Validation", value: "verificationResult", groupable: true, sortable: true }
];

export const codeRiskHeaders: (CommonTypedDataTableHeader | CodeRiskTypedDataTableHeader)[] = [
  { text: "License", value: "license", groupable: true, sortable: true, cellClass: "text-no-wrap" },
  { text: "Highest EPSS Score", value: "highestEPSSScore", groupable: false, sortable: true },
  { text: "SLA Start", value: "slaStart", groupable: true, sortable: true },
  { text: "Is Dev", value: "isDevDependency", groupable: true, sortable: true },
  { text: "Has Direct Vuln's", value: "hasDirectVulnerabilities", groupable: true, sortable: true },
  { text: "Package Type", value: "packageType", groupable: true, sortable: true },
  { text: "Package Name", value: "packageName", groupable: true, sortable: true },
  { text: "Has Fix", value: "hasFix", groupable: true, sortable: true },
  { text: "Vulnerability Class", value: "vulnerabilityClass", groupable: true, sortable: true }
];

export type StatusesConfig = PartialRecord<FindingStatuses, CodeStatus<FindingStatuses>>;

export abstract class FindingsTableConfig {
  protected constructor() {
    Vue.observable(this);
  }

  public abstract readonly availableHeaders: TypedDataTableHeader[];
  public abstract readonly dataTypes: Set<CodeRiskFindingType | SecretFindingType>;
  public abstract readonly statuses: StatusesConfig;

  public abstract selectedHeaderTypes: HeaderType[];
  public abstract filters: CodeRiskFilters;
  // common to both code risk and secrets
  public defaultSort = Object.freeze({
    by: ["risk", "lastUpdateDate"],
    desc: [false, true]
  });

  public resetFilters() {
    this.filters = CodeRiskFilters.reset();
    return Object.deepClone(this.filters);
  }
}

export class CodeRiskTableConfig extends FindingsTableConfig {
  public readonly availableHeaders = [...commonHeaders, ...codeRiskHeaders];
  public readonly dataTypes = new Set<CodeRiskFindingType>(Object.values(CodeRiskFindingType));
  public readonly statuses = CODE_RISK_STATUSES;

  public selectedHeaderTypes = [
    ...Object.entries({ ...commonHeaderTypes, ...codeRiskHeaderTypes })
      .filter(([, value]) => value)
      .map(([key]) => key)
  ] as CodeRiskHeaderType[];
  public filters;

  public constructor() {
    super();
    this.filters = CodeRiskFilters.load();
  }
}

const unifiedStatuses: Readonly<Record<string, CodeStatus<FindingStatuses>>> = Object.fromEntries(
  Object.entries(UNIFIED_FINDING_STATUSES).filter(([, value]) => !value.deprecated)
);

export class SecretsTableConfig extends FindingsTableConfig {
  public readonly availableHeaders = [...commonHeaders.filter((h) => h.value !== "dataType" && h.value !== "title" && h.value !== "lastUpdateDate"), ...secretHeaders];
  public readonly dataTypes = new Set<SecretFindingType.SECRET>(Object.values(SecretFindingType));
  public readonly statuses: StatusesConfig = unifiedStatuses;
  public selectedHeaderTypes = [
    ...Object.entries({ ...commonHeaderTypes, ...secretHeaderTypes })
      .filter(([, value]) => value)
      .map(([key]) => key)
  ] as SecretHeaderType[];
  public filters;

  public constructor() {
    super();
    this.filters = CodeRiskFilters.load();
  }
}

export class UnifiedFindingsTableConfig extends FindingsTableConfig {
  public filters;
  public readonly dataTypes = new Set<CodeRiskFindingType | SecretFindingType>([...Object.values(CodeRiskFindingType), ...Object.values(SecretFindingType)]);
  public readonly activities = new Set<FindingActivity>(Object.values(FindingActivity));
  public readonly statuses: StatusesConfig = unifiedStatuses;
  public selectedHeaderTypes = Object.entries(commonHeaderTypes)
    .filter(([, value]) => value)
    .map(([key]) => key) as HeaderType[];
  public availableHeaders = [...commonHeaders, ...codeRiskHeaders, ...secretHeaders];

  public constructor() {
    super();
    this.filters = CodeRiskFilters.load();
  }
}

export const secretsTableConfig = new SecretsTableConfig();
export const codeRiskTableConfig = new CodeRiskTableConfig();
export const unifiedFindingsTableConfig = new UnifiedFindingsTableConfig();
