<template>
  <v-dialog v-model="$state.Layout.userMenu.editProfile" class="component layout-component edit-profile-dialog" max-width="600px" scrollable transition="dialog-bottom-transition">
    <v-card>
      <v-card-title>
        <span class="text-h5">User Profile</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="email" disabled label="Email" readonly></v-text-field>
            </v-col>

            <v-col cols="12" v-if="orgName">
              <v-text-field label="Organization Name" v-model="organizationName" :rules="orgNameRules">
                <template v-slot:append-outer>
                  <v-btn small @click="updateOrgName" :disabled="updateOrgNameDisabled"> <v-icon small left color="success">mdi-content-save</v-icon>Save</v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="orgId">
            <v-col cols="12">
              <v-text-field label="Organization ID" readonly disabled :value="orgId">
                <template v-slot:append-outer>
                  <v-btn small @click="copyToClipboard(orgId)"> <v-icon small left color="success">mdi-clipboard-text-multiple-outline</v-icon> Copy </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="orgId" cols="12">
              <role-chip :color="role.color" :icon="role.icon" :label="role.label"></role-chip>
              of the
              <v-icon small>mdi-office-building</v-icon>
              <template v-if="orgName">
                <strong>{{ orgName }}</strong> <small>[{{ orgId }}]</small>
              </template>
              <template v-else>
                <strong>{{ orgId }}</strong>
              </template>
              organization&nbsp;
            </v-col>
          </v-row>
          <v-row v-if="orgId && !managedBySso">
            <v-col cols="12">
              <v-alert outlined type="error">
                <span class="text-h6 float-start">Danger Zone</span><v-spacer></v-spacer>
                <v-btn color="error" class="float-end" outlined @click="leaveOrganization" small><v-icon left small>mdi-delete</v-icon>{{ role.remove }}</v-btn>
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="$state.Auth.isArnicaAdmin">
            <v-col cols="12">
              <v-alert outlined color="warning">
                <v-autocomplete v-model="overrideTenant" :item-text="(x) => `${x.name} [${x.id}]`" :items="filteredOrganizations" clearable auto-select-first item-value="id">
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name | middleEllipses(50) }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.id | middleEllipses(50) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text> owner: {{ item.owner }}</v-list-item-action-text>
                      <v-list-item-action-text
                        >{{ item.meta?.integrationCount ?? 0 }} integrations, {{ item.meta?.lastReport?.identities ?? 0 }} identities
                      </v-list-item-action-text>
                      <v-list-item-action-text>
                        created: <span v-if="item.createdDate">{{ item.createdDate | dateFromNow }}</span
                        ><span v-else>unknown</span>, {{ item.tier | tierName }} tier
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
                <v-btn v-if="overrideTenant !== $state.Auth._selectedOrgId" small color="primary" @click.stop="$state.Auth.setSelectedOrgId(overrideTenant)"
                  >Switch to tenant</v-btn
                >

                <v-btn
                  small
                  @click.stop="
                    overrideTenant = null;
                    $state.Auth.setSelectedOrgId(null);
                  "
                  >Reset</v-btn
                >

                <v-checkbox dense hide-details v-model="showOrgsWithNoIntegrations" label="Show orgs with no integrations"></v-checkbox>
                <v-checkbox dense hide-details v-model="showOrgsWithArnicaOwnerEmail" label="Show orgs with @arnica.io owner"></v-checkbox>
                <v-checkbox dense hide-details v-model="showOrgsWithNoIdentities" label="Show orgs with no identities"></v-checkbox>
                <v-checkbox dense hide-details v-model="showOrgsWithFreeTier" label="Show orgs with free tier"></v-checkbox>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>

        <!--          <small>*indicates required field</small>-->
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="onClose">Close</v-btn>
        <!--          <v-btn color="blue darken-1" text @click.stop="onSave">Save</v-btn>-->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { RoleInfo } from "./role-converter";
import { Component, Vue } from "vue-property-decorator";
import { Organization, Role } from "$/dynamo";
import { Tier } from "$/interfaces/ui-api/billing-tiering/tier";
import { orgNameRules } from "$/validation/org-name-validation";
import ConfirmLink from "@/components/confirm-link.vue";
import RoleChip from "@/components/role-chip.vue";
import { copyToClipboard } from "@/utils";

type ValidationRule = (candidate: string) => string | boolean;

@Component({
  methods: { copyToClipboard },
  components: {
    ConfirmLink,
    RoleChip
  }
})
class EditProfileDialog extends Vue {
  protected email = this.$state.Auth.user?.email;
  protected organizationName = this.$state.Auth.user?.userInfo?.orgName;
  private organizations: Organization[] | null = null;
  protected overrideTenant: string | null = null;
  protected showOrgsWithNoIntegrations = false;
  protected showOrgsWithArnicaOwnerEmail = this.$state.Env.isDev;
  protected showOrgsWithNoIdentities = false;
  protected showOrgsWithFreeTier = true;

  public async beforeMount(): Promise<void> {
    if (this.$state.Auth.isArnicaAdmin) {
      const [orgs, selected] = await Promise.all([this.$api.Orgs.getAll(), this.$state.Auth.getSelectedOrgId()]);
      this.organizations = orgs ?? null;
      this.overrideTenant = selected;
    }
  }

  protected readonly orgNameRules: ValidationRule[] = orgNameRules;

  protected get role() {
    const { role } = this.$state.Auth.user?.userInfo || {};
    return RoleInfo.from(role || Role.read_only);
  }

  protected get orgName() {
    return this.$state.Auth.user?.userInfo?.orgName;
  }

  protected get orgId() {
    return this.$state.Auth.user?.userInfo?.orgId;
  }

  protected get updateOrgNameDisabled() {
    return !this.organizationName?.length || this.organizationName === this.$state.Auth.user?.userInfo?.orgName;
  }

  protected get managedBySso() {
    const { userInfo } = this.$state.Auth.user || {};
    return userInfo?.username.startsWith("samlp");
  }

  protected get filteredOrganizations(): Organization[] | null {
    if (this.organizations === null) {
      return null;
    }
    return this.organizations.filter((org) => {
      if (org.id === this.overrideTenant) {
        // always include the selected override tenant in the list if there is one
        return true;
      }
      if (org.deleted) {
        return false;
      }
      if (!this.showOrgsWithNoIntegrations && !org.meta?.integrationCount) {
        return false;
      }
      if (!this.showOrgsWithArnicaOwnerEmail && org.owner?.endsWith("@arnica.io")) {
        return false;
      }

      if (!this.showOrgsWithNoIdentities && !org.meta?.lastReport?.identities) {
        return false;
      }

      if (!this.showOrgsWithFreeTier && org.tier === Tier.free) {
        return false;
      }

      return true;
    });
  }

  protected set filteredOrganizations(value: Organization[] | null) {
    this.organizations = value;
  }

  protected onSave() {
    this.$state.Layout.userMenu.editProfile = false;
  }

  protected onClose() {
    this.$state.Layout.userMenu.editProfile = false;
  }

  protected async leaveOrganization() {
    if (await this.$state.Dialog.confirm(`${this.role.remove}`, `Are you sure you want to ${this.role.remove.toLowerCase()}? You will not be able to undo this action.`)) {
      await this.$api.Orgs.leave();
      await this.$state.Auth.signOut();
      // TODO: replace it with an update to the state object instead!
      window.location.reload();
    }
  }

  protected async updateOrgName() {
    if (this.organizationName !== null && typeof this.organizationName !== "undefined") {
      await this.$api.Orgs.updateName(this.organizationName);
      if (!this.$state.Auth.user) {
        throw new Error("User is required");
      }
      if (!this.$state.Auth.user?.userInfo) {
        throw new Error("User info is required");
      }
      this.$state.Auth.user.userInfo.orgName = this.organizationName;
    } else {
      throw new Error("Organization name is required");
    }
  }
}

export default EditProfileDialog;
</script>
