import { ApiBase } from "./api-base";
import { AxiosRequestConfig } from "axios";
import {
  UserFilter,
  UserFilterWithCounts,
  UserInventoryEmail,
  UserInventoryItem,
  UserInventoryItemDetails,
  UserSort
} from "$/inventory-v2/user/user";
import { UIUserInventoryItem } from "@/pages/inventory-v2/users-table/ui-user-inventory-item";
import { UpdateEmail } from "$/inventory-v2/user/email";

const ENABLE_SEARCH_VERB = !!new URLSearchParams(window.location.search).get("searchVerb");

interface GetUsersOptions {
  page: number;
  pageSize: number;
  filter?: UserFilter;
  sort?: UserSort;
  search?: string;
  silent?: boolean;
}

export const ScmUsers = new (class ScmUsersApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "inventory/users", name: "UsersApi" });
  }
  public async getAll({ page = 1, pageSize = 20, filter, sort, search, silent = false }: GetUsersOptions): Promise<UserInventoryItem[]> {
    const config: AxiosRequestConfig = {
      method: ENABLE_SEARCH_VERB ? "SEARCH" : "POST",
      silent,
      params: {
        page,
        pageSize
      },
      data: { filter, sort, search }
    };

    const res = await this.client.request<UserInventoryItem[]>(config);
    return res.data ?? null;
  }

  public async getCounts(filter?: UserFilter, search?: string, silent = false): Promise<number> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<number>("/counts", { filter, search }, config);
    return res.data ?? null;
  }

  public async getFilterCounts(
    filter?: UserFilter,
    search?: string,
    column?: UserFilterWithCounts,
    silent = false
  ): Promise<PartialRecord<keyof UserFilter, Record<string, number>> | null> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<PartialRecord<keyof UserFilter, Record<string, number>> | null>("/filter-counts", { filter, search, column }, config);
    return res.data ?? null;
  }

  public async getDetails(item: UIUserInventoryItem): Promise<UserInventoryItemDetails | null> {
    const config: AxiosRequestConfig = {
      silent: false
    };

    try {
      const res = await this.client.get<UserInventoryItemDetails | null>(`/details/${item.id.encodeURI()}`, config);
      return res.data ?? null;
    } catch (e) {
      console.error(`getDetails: ${e}`);
      throw e;
    }
  }

  public async updatePrimaryDisplayName(identityId: string, name: string) {
    try {
      await this.client.patch(`/${identityId.encodeURI()}/primary-display-name/${name.encodeURI()}`);
    } catch (e) {
      console.error(`updatePrimaryDisplayName: ${e}`);
      throw e;
    }
  }

  public async updatePrimaryEmailAddress(identityId: string, email: string) {
    try {
      await this.client.patch(`/${identityId.encodeURI()}/primary-email-address/${email.encodeURI()}`);
    } catch (e) {
      console.error(`updatePrimaryEmailAddress: ${e}`);
      throw e;
    }
  }

  public async addCustomEmailAddress(scmUserId: string, email: string): Promise<UserInventoryEmail> {
    try {
      const result = await this.client.post(`/emails/${scmUserId.encodeURI()}/custom/${email.encodeURI()}`);
      return result.data;
    } catch (e) {
      console.error(`addCustomDisplayName: ${e}`);
      throw e;
    }
  }

  public async editCustomEmailAddress(scmUserId: string, oldEmailAddress: string, newEmailAddress: string): Promise<UserInventoryEmail> {
    try {
      const body: UpdateEmail = {
        newEmailAddress
      };
      const result = await this.client.put(`/emails/${scmUserId.encodeURI()}/custom/${oldEmailAddress.encodeURI()}`, body);
      return result.data;
    } catch (e) {
      console.error(`editCustomEmailAddress: ${e}`);
      throw e;
    }
  }

  public async deleteCustomEmailAddress(scmUserId: string, email: string): Promise<void> {
    try {
      await this.client.delete(`/emails/${scmUserId.encodeURI()}/custom/${email.encodeURI()}`);
    } catch (e) {
      console.error(`deleteCustomEmailAddress: ${e}`);
      throw e;
    }
  }

})();
