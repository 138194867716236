import { ConfigFlags } from "@arnica-internal/configs";
import "vue-router";
import { Lazy } from "$/utility-types";
import { Flags } from "@/state";
import { ObservablePromiseBase } from "@/utility-types/observable-promise";

declare module "vue-router/types/router" {
  export interface RouteMeta {
    /**
     * Whether the route should be shown in the navigation drawer (sidebar)
     * @note Possible value types:
     * - `true`: to always show the route
     * - `false`: to never show the route
     * - `FlagCheck`: to check if the route should be shown based on a `flag`
     * - `ObservablePromiseBase<boolean>`: update visibility after the promise resolves, uses default observable value until then
     * - `Lazy<boolean>`: uses the `value` property of the lazy object
     */
    sidebar?: boolean | FlagCheck | ObservablePromiseBase<boolean> | Lazy<boolean> | Lazy<ObservablePromiseBase<boolean>>;
  }
}

export class FlagCheck<F extends keyof ConfigFlags = keyof ConfigFlags> extends Lazy<ObservablePromiseBase<boolean>> {
  private readonly predicate: (value: ConfigFlags[F] | null) => boolean;

  /**
   * Check if the flag is truthy
   * @param flag The flag to check
   */
  public constructor(flag: F);
  /**
   * Check if the flag passes the predicate
   * @param flag The flag to check
   * @param predicate The predicate to check the flag with
   */
  public constructor(flag: F, predicate: (value: ConfigFlags[F] | null) => boolean);
  public constructor(private readonly flag: F, predicate?: (value: ConfigFlags[F] | null) => boolean) {
    super(() => this.isEnabled().observe(false));
    this.predicate = predicate ?? ((value) => !!value);
  }

  private async isEnabled(): Promise<boolean> {
    const flagValue = await Flags.tryGet(this.flag);
    const result = this.predicate(flagValue);
    return result;
  }
}
