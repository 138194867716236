import { Role } from "$/dynamo";

export interface RoleInfo {
  role: Role;
  icon: string;
  color: string;
  label: string;
  remove: string;
  assignable: boolean;
}

export const ROLE_CONVERTER: Record<Role, RoleInfo> = {
  [Role.owner]: {
    role: Role.owner,
    icon: "mdi-account-tie",
    color: "primary",
    label: "Owner",
    remove: "Delete Organization",
    assignable: false
  },
  [Role.admin]: {
    role: Role.admin,
    icon: "mdi-shield-account",
    color: "",
    label: "Admin",
    remove: "Leave Organization",
    assignable: true
  },
  [Role.security_reviewer]: {
    role: Role.security_reviewer,
    icon: "mdi-security",
    color: "",
    label: "Security Reviewer",
    remove: "Leave Organization",
    assignable: true
  },
  [Role.collaborator]: {
    role: Role.collaborator,
    icon: "mdi-account-multiple",
    color: "",
    label: "Collaborator",
    remove: "Leave Organization",
    assignable: true
  },
  [Role.read_only]: {
    role: Role.read_only,
    icon: "mdi-eye",
    color: "",
    label: "Read Only",
    remove: "Leave Organization",
    assignable: true
  },
  [Role.magic_link]: {
    role: Role.magic_link,
    icon: "mdi-link",
    color: "",
    label: "Magic Link",
    remove: "",
    assignable: false
  },
  [Role.anonymous]: {
    role: Role.anonymous,
    icon: "mdi-incognito",
    color: "",
    label: "Anonymous",
    remove: "Leave Organization",
    assignable: false
  },
  [Role.wretch]: {
    role: Role.wretch,
    icon: "mdi-account-off",
    color: "",
    label: "Wretch",
    remove: "Leave Organization",
    assignable: false
  }
};

export const RoleInfo = {
  map: ROLE_CONVERTER,
  from(role: Role): RoleInfo {
    return ROLE_CONVERTER[role];
  }
} as const;

export default ROLE_CONVERTER;
