import moment from "moment";
import Vue from "vue";
import { DetectorTask, JobStatus, JobType, isConcludedJobStatus, isOngoingJobStatus } from "$/dynamo";
import { IntegrationType } from "$/dynamo";
import { GetJobsResponseDto } from "$/interfaces/ui-api/response/get-jobs-response";
import { Jobs as Api } from "@/api";
import { jobStatusColorFilter, jobStatusIconFilter, jobStatusNameFilter } from "@/filters/job-filters";
import { Auth } from "@/state/auth-state";

type ProgressRecordKey = Exclude<JobStatus, "awaiting-sub-jobs"> | "total";
type ProgressRecord = Record<ProgressRecordKey, number>;

type StatusDisplayProps = {
  color: string;
  icon: string;
  text: string;
};

export class UIGetJobsResponse implements GetJobsResponseDto {
  public sortKey!: string;
  public status!: JobStatus;

  public type!: JobType;
  public integrationOrgIdAndType!: string;
  public integrationType!: IntegrationType;
  public integrationOrgId!: string;
  public createdAt!: string;
  public createdBy!: string;
  public updatedAt!: string;
  public error?: string;
  public isSelfHosted!: boolean;
  public originalCreatedAt?: string;
  private _childJobs: UIGetJobsResponse[] | null = null;
  public parentJobSortKey?: string;
  public repoId?: string;
  public repoName?: string;
  public projectId?: string;
  public projectName?: string;
  public detectorTask?: DetectorTask;
  public statusDisplayProps!: StatusDisplayProps;
  public errorRestartDate?: string;
  public constructor(init: GetJobsResponseDto) {
    Object.assign(this, init);
    this.statusDisplayProps = this.getStatusDisplayProps();
    Vue.observable(this);
  }

  public get childJobs(): UIGetJobsResponse[] | null {
    return this._childJobs?.filter((j) => j.sortKey !== this.sortKey) ?? null;
  }

  public set childJobs(value: UIGetJobsResponse[] | null) {
    this._childJobs = value;
    this.statusDisplayProps = this.getStatusDisplayProps();
  }

  public get totalTime(): number {
    const startTime = new Date(this.createdAt).getTime();
    const endTime = isConcludedJobStatus(this.status) ? new Date(this.updatedAt).getTime() : new Date().getTime();
    return endTime - startTime;
  }

  /*
  public get estimatedTimeRemaining(): number | null {
    if (!this.childJobsProgress || !(this.status === "running" || this.status === "awaiting-sub-jobs")) {
      return null;
    }
    const jobStartTime = new Date(this.updatedAt).getTime();
    const curTime = new Date().getTime();
    const complete = this.childJobsProgress.complete;
    const total = this.childJobsProgress.total;
    if (complete === 0) {
      return null;
    }
    const timePerJob = (curTime - jobStartTime) / complete;
    const remainingJobs = total - complete;
    return timePerJob * remainingJobs;
  }
*/

  public get asset(): string | null {
    if (!this.projectName && !this.repoName) {
      return null;
    }
    return [this.projectName, this.repoName].nonNullable().join("/");
  }

  public static getStatusDisplayProps(status: JobStatus): StatusDisplayProps {
    return {
      color: jobStatusColorFilter(status),
      text: jobStatusNameFilter(status),
      icon: jobStatusIconFilter(status)
    };
  }

  private getStatusDisplayProps(): StatusDisplayProps {
    const defaultReturn = UIGetJobsResponse.getStatusDisplayProps(this.status);

    if (this.status === "pending" && this.errorRestartDate) {
      return {
        color: "warning",
        icon: "mdi-timer-sand",
        text: `Retrying in ${moment(this.errorRestartDate).fromNow()}`
      };
    }

    if (!this.childJobs) {
      return defaultReturn;
    }
    const progress = this.childJobsProgress;
    if (!progress) {
      return defaultReturn;
    }
    const { total, complete, error, "permission-error": permissionError } = progress;
    const concluded = complete + error + permissionError;
    if (total === 0) {
      return defaultReturn;
    }
    // if any status count === the total, the status stays the same
    for (const [key, value] of Object.entries(progress)) {
      if (value === total && key !== "total") {
        return UIGetJobsResponse.getStatusDisplayProps(key as JobStatus);
      }
    }

    // if all jobs are concluded (complete, error, permission-error)
    if (concluded === total) {
      // this implies there are some errors, if it was all errors, all complete or all permission errors, it would have been caught above
      if (complete > 0) {
        return {
          color: "warning",
          icon: "mdi-alert-circle",
          text: `Partially completed`
        };
      }
      // edge case, if the above didn't catch it, we have both errors and permission errors but no complete
      return UIGetJobsResponse.getStatusDisplayProps("error");
    }

    // not concluded and not all pending === in progress

    return {
      color: "info",
      icon: "mdi-run-fast",
      text: `In progress`
    };
  }

  public get childJobsProgressPercent(): string {
    if (!this.childJobsProgress || this.childJobsProgress.total === 0) {
      return "0";
    }
    return Math.floor((this.childJobsProgress.complete / this.childJobsProgress.total) * 100).toFixed(0);
  }
  public get childJobsProgress(): ProgressRecord | null {
    const ret: ProgressRecord = {
      total: 0,
      error: 0,
      complete: 0,
      "permission-error": 0,
      running: 0,
      pending: 0
    };

    if (!this.childJobs && this.parentJobSortKey && this.status !== "awaiting-sub-jobs") {
      //leaf node
      ret[this.status] = 1;
      ret.total = 1;
      return ret;
    }
    if (!this.childJobs) {
      return null;
    }
    for (const childJob of this.childJobs) {
      const childJobProgress = childJob.childJobsProgress;
      if (!childJobProgress) {
        continue;
      }
      for (const [key, value] of Object.entries(childJobProgress)) {
        ret[key as keyof typeof ret] += value;
      }
    }
    return ret;
  }

  public static fromDto(dto: GetJobsResponseDto): UIGetJobsResponse {
    return new UIGetJobsResponse(dto);
  }
}

export interface JobWatcher {
  onAllJobsChanged?(jobs: UIGetJobsResponse[]): void;
  onRootJobsChanged?(jobs: UIGetJobsResponse[] | null): void;
}

class JobsState {
  private readonly watchers: Set<JobWatcher> = new Set();
  private interval: number | null = null;

  private _allJobs: Promise<UIGetJobsResponse[]> | null = null;
  public get allJobs(): Promise<UIGetJobsResponse[]> {
    return (this._allJobs ??= this.getAllJobs());
  }
  private async getAllJobs(): Promise<UIGetJobsResponse[]> {
    const result = await Api.get();
    const jobs = result.map((j) => UIGetJobsResponse.fromDto(j));
    const jobsByParentJob = jobs.groupBy((j) => j.parentJobSortKey ?? null);
    for (const job of jobs) {
      job.childJobs = jobsByParentJob.get(job.sortKey) ?? null;
    }
    return jobs;
  }

  private _rootJobs: Promise<UIGetJobsResponse[] | null> | null = null;
  public get rootJobs(): Promise<UIGetJobsResponse[] | null> {
    if (!Auth.user?.registered) {
      return Promise.resolve(null);
    }
    return (this._rootJobs ??= this.getRootJobs(this.allJobs));
  }
  private async getRootJobs(allJobs: Promise<UIGetJobsResponse[]>): Promise<UIGetJobsResponse[] | null> {
    if (!Auth.user?.registered) {
      return Promise.resolve(null);
    }
    const jobs = await allJobs;
    const rootJobs = jobs?.filter((j) => !j.parentJobSortKey);
    return rootJobs?.length ? rootJobs : null;
  }

  public constructor() {
    Vue.observable(this);
  }

  /**
   * Add a jobs polling watcher
   * @param watcher The watcher object to add
   */
  public watch(watcher: JobWatcher) {
    this.watchers.add(watcher);
    this.updatePolling();
    this.allJobs.then((jobs) => watcher.onAllJobsChanged?.(jobs));
    this.rootJobs.then((jobs) => watcher.onRootJobsChanged?.(jobs));
  }

  /**
   * Remove a jobs polling watcher
   * @param watcher The watcher object to remove
   */
  public unwatch(watcher: JobWatcher) {
    this.watchers.delete(watcher);
    this.updatePolling();
  }

  private updatePolling() {
    if (this.watchers.size) {
      if (this.interval === null) {
        void this.refreshJobs();
        this.interval = window.setInterval(() => this.checkIfJobsShouldBeRefreshed(), 60_000);
      }
    } else if (this.interval !== null) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
  }

  private async checkIfJobsShouldBeRefreshed() {
    if (await this.hasJobsInProgress()) {
      await this.refreshJobs();
    } else if (this.interval !== null) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
  }

  public async refreshJobs() {
    // Get promises with latest data
    const allJobs = this.getAllJobs();
    const rootJobs = this.getRootJobs(allJobs);

    // Update the current promises if they are not already set
    this._allJobs ??= allJobs;
    this._rootJobs ??= rootJobs;

    // Wait for promises to resolve
    await Promise.all([allJobs, rootJobs]);

    // Swap-in the new promises (with data preloaded)
    this._allJobs = allJobs;
    this._rootJobs = rootJobs;

    // Trigger watchers
    for (const watcher of this.watchers) {
      watcher.onAllJobsChanged?.(await allJobs);
      watcher.onRootJobsChanged?.(await rootJobs);
    }
  }

  public get hasIngestOrDetectJobsInProgress(): Promise<boolean> {
    return this.hasJobsInProgress();
  }

  public async hasJobsInProgress(jobTypes: JobType[] = ["ingest", "detect", "activity-scan"]): Promise<boolean> {
    return !!(await this.rootJobs)?.some((j) => jobTypes.includes(j.type) && isOngoingJobStatus(j.status));
  }
}
export const Jobs = new JobsState();
