import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { GetSignedUrlResponse } from "$/interfaces/ui-api/response/get-signed-url-response";
import { UnauthorizedError } from "@/errors/unauthorized-error";
import { router } from "@/plugins";
import { AnalyticsService } from "@/services/analytics-service";
import { Auth, Demo, Layout } from "@/state";
import { AppSettings } from "@/state/app-settings";

export interface BaseRequestParams {
  /**
   * default false, if true, skips the progress bar when loading the request
   */
  silent?: boolean | undefined;
  /**
   * default false, if true, adds a random nonce query param
   */
  noCache?: boolean | undefined;
}

declare module "axios" {
  interface AxiosRequestConfig extends BaseRequestParams {
    tracker?: symbol | undefined;
    retires?: number | undefined;
    anonymous?: boolean | undefined;
  }
}

export interface ApiBaseOptions {
  pathPrefix: string;
  name: string;
}

export abstract class ApiBase {
  protected readonly client: AxiosInstance;
  private baseUrlPromise: Promise<string> | null = null;

  protected constructor(private readonly options: ApiBaseOptions) {
    this.client = this.build();
  }

  private get name(): string {
    return this.options.name;
  }
  private get pathPrefix(): string {
    return this.options.pathPrefix;
  }

  protected get logRequests(): boolean {
    return true;
  }

  protected get logResponses(): boolean {
    return true;
  }

  private get baseUrl(): Promise<string> {
    return (this.baseUrlPromise ||= this.getBaseUrl());
  }
  protected async getBaseUrl(): Promise<string> {
    const apiOrigin = await AppSettings.getApiOriginExternal();
    return `${apiOrigin}/${this.pathPrefix}`;
  }

  private build(): AxiosInstance {
    const instance = Axios.create({
      onDownloadProgress: (progress) => {
        progress; // console.debug(`${this.name}: downloading`, progress);
      },
      onUploadProgress: (progress) => {
        progress; // console.debug(`${this.name}: uploading`, progress);
      }
    });

    // instance.interceptors.request.use(this.demoInterceptRequest.bind(this), undefined, { runWhen: () => Demo.enabled });
    // instance.interceptors.response.use(this.demoInterceptResponse.bind(this), this.demoInterceptError.bind(this), {
    //   runWhen: () => Demo.enabled
    // });
    instance.interceptors.request.use(this.onRequest.bind(this));
    instance.interceptors.response.use(this.onResponse.bind(this), this.onResponseError.bind(this));

    return instance;
  }

  // private demoInterceptRequest(config: AxiosRequestConfig) {
  //   return Demo.requestInterceptor(config);
  // }

  protected async demoInterceptError(error: unknown): Promise<unknown> {
    return await Demo.errorInterceptor(error);
  }

  protected async demoInterceptResponse(response: AxiosResponse<unknown>): Promise<AxiosResponse<unknown>> {
    return await Demo.responseInterceptor(response);
  }

  protected async onRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    config.baseURL = await this.baseUrl;
    this.logRequests && console.debug(`${this.name}: request`, config);
    config.tracker = config.silent ? undefined : Symbol();
    Layout.track(config.tracker);
    config.retires ??= 1;
    if (config.noCache) {
      config.params ??= {};
      config.params.nonce = Date.now();
    }
    await this.authenticate(config);
    return config;
  }

  protected async onResponse(response: AxiosResponse<unknown>): Promise<AxiosResponse<unknown>> {
    this.logResponses && console.debug(`${this.name}: response`, response);
    Layout.untrack(response.config.tracker);
    if (response.status === 401) {
      throw new UnauthorizedError("Unauthorized");
    }
    return await this.followSignedUrlDownload(response);
  }

  protected async onResponseError(error: unknown): Promise<unknown> {
    console.error(`${this.name}: response error`, error, (error as AxiosError).response?.data ?? "no payload");
    if (!Axios.isAxiosError(error)) {
      throw error;
    }
    const config = error.config;
    Layout.untrack(config.tracker);

    config.retires ??= 1;
    config.retires--;
    if (config.retires <= 0) {
      throw error;
    }

    if (error.response?.status === 401) {
      await this.authenticate(config);
      return await this.client.request(config);
    }
    throw error;
  }

  protected async followSignedUrlDownload(response: AxiosResponse<unknown>): Promise<AxiosResponse<unknown>> {
    const { data } = response;
    if (data && typeof data === "object" && "signedUrl" in data) {
      const { signedUrl } = data as GetSignedUrlResponse;
      if (!signedUrl) {
        response.data = null;
        return response;
      }
      const followData = await this.client.get<unknown>(signedUrl, {
        anonymous: true,
        responseType: response.config.params?.forDownload ? "text" : "json"
      });
      return followData;
    }
    return response;
  }

  private async authenticate(config: AxiosRequestConfig): Promise<void> {
    // TODO: right logic?
    if (config.anonymous) {
      return;
    }
    (config.headers ??= {})["Authorization"] = await this.getIdToken();
  }

  protected async getIdToken(): Promise<string> {
    try {
      if (!Auth.authenticated) {
        throw new UnauthorizedError("User is not authenticated");
      }
      const idToken = await Auth.getIdToken();
      if (!idToken) {
        throw new UnauthorizedError("No ID token");
      }
      return idToken;
    } catch (e) {
      //Popup.error("You are no longer signed in, redirecting to login page");
      AnalyticsService.logEvent("User Session Timed Out - Redirected To Sign In", undefined, { group: "General" });
      try {
        await router.replace({ name: "login" });
      } catch (e2) {
        console.log("Failed to redirect to login", e2);
      }
      //TODO: save state so users can go back to where they left off
      //TODO: redirect to login...
      throw e;
    }
  }
}
