import type { CommunicationIntegrationType } from "../integration";
import type { IntegrationAttributes } from "./integration-attributes";

interface CommunicationIntegrationAttributes extends IntegrationAttributes {
  type: CommunicationIntegrationType;
  hasJobs: false;
}

export const CommunicationIntegrationAttributes = {
  slack: {
    type: "slack",
    category: "communication",
    displayName: "Slack",
    hasJobs: false,
    includeMetadata: true,
    ui: {
      icon: "mdi-slack",
      editable: false,
      removable: true
    }
  },
  msteams: {
    type: "msteams",
    category: "communication",
    displayName: "Microsoft Teams",
    hasJobs: false,
    includeMetadata: false,
    ui: {
      icon: "mdi-microsoft-teams",
      editable: false,
      removable: true
    }
  }
} as const satisfies Record<CommunicationIntegrationType, CommunicationIntegrationAttributes>;
