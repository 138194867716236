import { ApiBase } from "./api-base";
import axios from "axios";
import moment from "moment";
import type { IntegrationOrgIdAndType, LlmIntegrationMetadata, LlmIntegrationType } from "$/dynamo";
import type { AiSolutionResponse } from "$/interfaces/ai-solution";
import { IntegrationInitiationRequest } from "$/interfaces/ui-api/request/integrations/integration-initiation-request";
import type { CodeRiskFinding } from "$/sast/code-risk-finding";

class LlmApi extends ApiBase {
  public readonly timeout = moment.duration(1, "minute");

  public constructor() {
    super({ pathPrefix: "llm", name: "LlmApi" });
  }

  public async generateSolution(
    dataType: CodeRiskFinding["dataType"],
    scanner: CodeRiskFinding["scanner"],
    id: string,
    integrationOrgIdAndType?: IntegrationOrgIdAndType<LlmIntegrationType>
  ): Promise<AiSolutionResponse | null> {
    try {
      const res = await this.client.post<AiSolutionResponse>(`${dataType.encodeURI()}/${scanner.encodeURI()}/${id.encodeURI()}`, null, {
        params: {
          integrationOrgIdAndType
        },
        timeout: this.timeout.asMilliseconds()
      });
      return res.data ?? null;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async validate(request: IntegrationInitiationRequest): Promise<boolean> {
    return (await this.client.post<boolean>(`/${request.type.encodeURI()}/validate`, request)).data;
  }

  public async updateModel(integrationType: LlmIntegrationType, integrationOrgId: string, metadata: LlmIntegrationMetadata): Promise<void> {
    await this.client.patch<void>(`${integrationType.encodeURI()}/${integrationOrgId.encodeURI()}/model`, metadata);
  }
}

export const Llm = new LlmApi();
