/**
 * @deprecated RBAC: get rid of the old enum (OLD_ROLE) and use the new one (role)
 */
export enum OLD_ROLE {
  owner = 300,
  admin = 200,
  member = 100,
  wretch = 0 // No privilege
}

export enum Role {
  owner = "owner",
  admin = "admin",
  security_reviewer = "security_reviewer",
  collaborator = "collaborator",
  read_only = "read_only",
  magic_link = "magic_link",
  anonymous = "anonymous",
  wretch = "wretch"
}
