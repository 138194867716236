import { ApiBase } from "./api-base";
import axios, { AxiosRequestConfig } from "axios";
import { FixVersionDto } from "$/detectors/handlers/utils/dependency-fix-recommender/fix-versions";
import { IntegrationType } from "$/dynamo";
import { FindingFilter, FindingFilterWithCounts, FindingSort } from "$/dynamo/findingBase";
import { FindingsHistory } from "$/finding-types";
import { FindingFixPullRequest } from "$/findings/finding-fix-pull-requests";
import { CodeRiskFinding, CodeRiskFindingPayload } from "$/sast/code-risk-finding";
import type { HasSortKey } from "$/utility-types/has-sort-key";

const ENABLE_SEARCH_VERB = !!new URLSearchParams(window.location.search).get("searchVerb");

class CodeRiskApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "risk/code", name: "CodeRiskApi" });
  }

  public async getCodeRiskReport(
    silent = false,
    integrationType: IntegrationType,
    integrationOrgId: string,
    project: string | undefined,
    repo: string,
    dataType: CodeRiskFinding["dataType"],
    scanner: CodeRiskFinding["scanner"]
  ): Promise<CodeRiskFinding[] | null> {
    const config: AxiosRequestConfig = {
      silent
    };

    const projectAndRepo = project ? `${project}/${repo}` : repo;
    const res = await this.client.get<CodeRiskFinding[] | null>(
      `${dataType.encodeURI()}/${scanner.encodeURI()}/${integrationType.encodeURI()}/${integrationOrgId.encodeURI()}/${projectAndRepo.encodeURI()}`,
      config
    );
    return res.data ?? null;
  }

  public async deleteFinding(sortKey: string): Promise<void> {
    await this.client.delete(sortKey.encodeURI());
  }

  public async getCodeRiskReportWithPagination(
    page: number,
    pageSize: number,
    filter?: FindingFilter,
    sort?: FindingSort,
    search?: string,
    silent = false
  ): Promise<{
    items: CodeRiskFinding[];
    total: number | undefined;
  } | null> {
    const config: AxiosRequestConfig = {
      method: ENABLE_SEARCH_VERB ? "SEARCH" : "POST",
      silent,
      params: {
        page,
        pageSize
      },
      data: { filter, sort, search }
    };

    const res = await this.client.request<{
      items: CodeRiskFinding[];
      total: number | undefined;
    } | null>(config);
    return res.data ?? null;
  }

  public async getCodeRiskCounts(filter?: FindingFilter, search?: string, silent = false): Promise<number> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<number>("/counts", { filter, search }, config);
    return res.data ?? null;
  }

  public async getCodeRiskReportFilterCounts(
    filter?: FindingFilter,
    search?: string,
    column?: FindingFilterWithCounts,
    silent = false
  ): Promise<PartialRecord<keyof FindingFilter, Record<string, number>> | null> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<PartialRecord<keyof FindingFilter, Record<string, number>> | null>("/filter-counts", { filter, search, column }, config);
    return res.data ?? null;
  }

  public async getCodeRiskReportDetails<T extends CodeRiskFinding = CodeRiskFinding>(
    _dataType: CodeRiskFinding["dataType"],
    _scanner: CodeRiskFinding["scanner"],
    id: string
  ): Promise<NonNullable<HasSortKey<CodeRiskFindingPayload<T>>> | null> {
    try {
      const url = `${id.encodeURI()}/details`;
      const res = await this.client.get<HasSortKey<CodeRiskFindingPayload<T>> | null>(url);
      return res.data ?? null;
    } catch (e) {
      //TODO: add a config in axios base of ignore404: boolean to avoid repeating this / add a util method
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async getCodeRiskReportHistory(id: string): Promise<FindingsHistory[] | null> {
    try {
      const url = `${id.encodeURI()}/history`;
      const res = await this.client.get<FindingsHistory[]>(url);
      return res.data ?? null;
    } catch (e) {
      //TODO: add a config in axios base of ignore404: boolean to avoid repeating this / add a util method
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async getCodeRiskSingleFindingReport(sortKey: string, silent = false): Promise<CodeRiskFinding[] | null> {
    const config: AxiosRequestConfig = {
      silent
    };

    try {
      const res = await this.client.get<CodeRiskFinding | null>(`${sortKey.encodeURI()}`, config);
      const finding = res.data ?? null;
      return Array.guarantee(finding).nonNullable();
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async getCodeRiskMultipleFindingReport(sortKeys: string[], silent = false): Promise<CodeRiskFinding[] | null> {
    const config: AxiosRequestConfig = {
      silent
    };

    try {
      // we are using post instead of get because the sortKeys can be too long for a get request
      // most browsers / servers have a limit of 8kb for a get request (https://stackoverflow.com/a/2659995)
      // for post requests, some browsers support up to 4GB(!) https://serverfault.com/a/925688, where most support at least 2GB
      // for example, only 1000 findings, is 1.2mb gzipped, which won't fit in a get request.
      // alternate solutions - save the sortKeys in a table under a single UUID, and only store the UUID in the UI.
      const res = await this.client.post<CodeRiskFinding[] | null>("multi", sortKeys, config);
      const findings = res.data ?? null;
      return findings ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async createSharableLink(sortKey: string, short: boolean): Promise<string> {
    const res = await this.client.post<string>(`${sortKey.encodeURI()}/link?short=${short}`);
    return res.data;
  }

  public async createFindingFixPr(sortKey: string, fixVersion: FixVersionDto): Promise<FindingFixPullRequest | null> {
    const res = await this.client.post<FindingFixPullRequest | null>(`${sortKey.encodeURI()}/pr`, fixVersion);
    return res.data;
  }
}

export const CodeRisk = new CodeRiskApi();
