<template>
  <div class="component user-role">
    <div v-if="managedBySso && ssoRoles.length">
      <v-row class="badges-list">
        <v-tooltip bottom v-for="role in ssoRoles" :key="role">
          <template v-slot:activator="{ attrs, on }">
            <v-icon v-on="on" v-bind="attrs" right x-small>{{ toRoleInfo(role).icon }}</v-icon>
          </template>
          <span>{{ toRoleInfo(role).label }}</span>
        </v-tooltip>
      </v-row>
    </div>
    <div v-else>
      <v-icon left x-small>{{ roleInfo.icon }}</v-icon>
      <span>
        {{ roleInfo.label }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { RoleInfo } from "../layout/user-menu/role-converter";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Role } from "$/dynamo";
import { GetUserInfoResponse } from "$/interfaces/ui-api/response/get-user-info-response";
import { Auth } from "@/state";

const ROLES_VIEW_ORDER: Record<Role, number> = {
  [Role.owner]: 1,
  [Role.admin]: 2,
  [Role.security_reviewer]: 3,
  [Role.collaborator]: 4,
  [Role.read_only]: 5,
  [Role.anonymous]: 6,
  [Role.wretch]: 7
};

@Component
export default class UserRole extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly user!: GetUserInfoResponse;

  @Prop({ type: Boolean, default: false })
  protected readonly managedBySso!: boolean;

  protected get isOrgOwner() {
    const { role } = Auth.user?.userInfo || {};
    return role === Role.owner;
  }

  protected get ssoRoles() {
    return (this.user.ssoMatchingRoles || []).orderBy((a) => ROLES_VIEW_ORDER[a]);
  }

  protected get roleInfo() {
    return this.toRoleInfo(this.user.role);
  }

  protected toRoleInfo(role: Role) {
    return RoleInfo.from(role);
  }
}
</script>

<style lang="scss">
.component.user-role {
  display: flex;
  & .badges-list {
    display: flex;
    margin-top: -8px;
    margin-left: -8px;
  }
}
</style>
